<template>
  <div id="containera" :style="{ height: '100vh', position: 'relative' }">
    <div
      class="progress-outer flex flex-justify-center"
      v-if="loadProgress !== 100"
    >
      <el-progress
        class="progress flex-item flex-item-center"
        :text-inside="true"
        :stroke-width="22"
        :percentage="loadProgress"
        status="warning"
      ></el-progress>
    </div>
    <div class="name">{{ detail.name }}
      <div class="diaig_details" v-html="detail.keys  ">
          
      </div>
    </div>

        
    <div class="bd_weixin_popup" v-if="share_flag">
      <div
        id="bdshare_weixin_qrcode_dialog_bg"
        class="bd_weixin_popup_bg"
      ></div>
      <div class="bd_weixin_popup_head">
        <span class="share_title">分享到朋友圈</span>

        <div class="bd_weixin_popup_close" @click="guan">
          <img src="../assets/pop/close.png" alt="" />
        </div>
      </div>
      <div id="qrcode"></div>
      <span class="share_footer">
        打开微信，点击底部的“发现”， <br />
        使用“扫一扫”即可将网页分享至朋友圈。
      </span>
    </div>
    <div class="ce">
      <div class="ceImg">
        <img
          src="../assets/3DDetails/zhuye.png"
          alt=""
          @click="$router.push('/digit')"
        />
      </div>
      <!-- <div class="ceImg">
            <img src="../assets/3DDetails/shuji2.png" alt="">
          </div> -->
      <div class="qwe">
        <div class="ceImg">
          <img src="../assets/3DDetails/e0e06f159658bfdf11cd93d095ddf12b5ba7e7c32f31-xDzqGJ_fw1200.png" alt="" />
        </div>
        <div class="three">
          <div class="qwes" @click="haveCode">
            <img
              src="https://img.dpm.org.cn/Public/static/topic/vgugong/image/temp/icon4-1.png"
              alt=""
            />
          </div>
          <div class="qwes" @click="sinlang">
            <img
              src="https://img.dpm.org.cn/Public/static/topic/vgugong/image/temp/icon6-1.png"
              alt=""
            />
          </div>
          <div class="qwes margins">
            <img src="../assets/3DDetails/e0e06f159658bfdf11cd93d095ddf12b5ba7e7c32f31-xDzqGJ_fw1200.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@media screen and (max-width: 1200px) {
  #containera {
    height: 140vh !important;
  }
}
#containera {
  height: 100vh;
  background-color: #efeff0;
  position: relative;
  .progress-outer {
    // background-color: rgba($color: #000000, $alpha: 0.6);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    .progress {
      width: 600px;
      // margin: 25% auto 0;
    }
  }
}
.name {
  color: #bf9860;
  font-size: 48px;
  font-family: Fontquan-XinYiJiXiangSong;
  position: absolute;
  top: 16%;
  left: 6%;
}
.diaig_details{
  width: 310px;
  // height: 800px;
  // background-color: wheat;
  margin-top: 50px;
  font-size: 25px;
  text-align: left;
}
.margins {
  padding-left: 0;
}
.qwe {
  position: relative;
  z-index: 802;
}
.qwes {
  background-color: #ffffff;
  margin: 3px;
  padding: 19px;
  margin-right: 0;
  float: left;
  margin-left: 0;
}
.qwe:hover .three {
  display: block;
  /* background: olive; */
}
.three {
  width: 276px;
  display: none;
  position: absolute;
  top: -3px;
  right: -3px;
  float: left;
  z-index: 99;
  /* height: 100%; */
}
.two img {
  /* margin-right: 30px; */
}
.three img {
  height: 42px;
  width: 42px;
  margin: 5px;
  background-color: #ffffff;
  text-align: center;
}
.cangpinImg {
  height: 713px;
  /* width: 1276px; */
  position: absolute;
  margin: auto;
  left: 50%;
  margin-left: -280px;
  top: 50%;
  margin-top: -356px;
}
.bu_all {
  height: 983px;
  width: 100%;
  background: rgb(230, 231, 233);
  position: relative;
  box-sizing: border-box;
  background-size: 100% 100%;
  position: fixed;
  /* padding-left: 160px; */
  /* margin-top: 230px; */
}
.zhuan {
  width: 1276px;
  height: 713px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -632px;
  margin-top: -360px;
  /* background: violet; */
}
.shop_name {
  /* width: 228px; */
  height: 41px;
  font-size: 48px;
  font-family: 宋体;
  font-weight: 400;
  color: #bf9860;
  float: left;
}
.shop_chao {
  height: 41px;
  font-size: 48px;
  font-family: 宋体;
  font-weight: 400;
  color: #bf9860;
  margin-left: 37px;
  float: left;
}
.zuo {
  position: absolute;
  right: 422px;
  top: 451px;
  width: 25px;
  height: 45px;
}
.you {
  position: absolute;
  top: 450px;
  width: 25px;
  left: 417px;
  height: 45px;
}
.guan {
  position: absolute;
  top: 40%;
  left: 50%;
  width: 100px;
  height: 200px;
  margin-left: -100px;
}
.ce {
  position: absolute;
  right: 158px;
  top: 341px;
}
.ceImg {
  background-color: #ffffff;
  margin: 3px;
  padding: 19px;
}
.ce img {
  height: 42px;
  width: 42px;
  margin: 5px;
  background-color: #ffffff;
  text-align: center;
  /* margin: 19px; */
}
.Title {
  height: 41px;
  position: absolute;
  top: 139px;
  left: 164px;
}

.bd_weixin_popup {
  position: fixed;
  left: 50%;
  top: 50%;
  padding: 10px;
  width: 280px !important;
  height: 340px !important;
  background: #ffffff;
  border: solid 1px #d8d8d8;
  z-index: 11001;
  font-size: 12px;
  margin-left: -140px;
  margin-top: -170px;
}
.share_title {
  float: left;
  font-weight: bold;
}
.bd_weixin_popup_head {
  position: relative;
  width: 100%;
  height: 20px;
}
.bd_weixin_popup_close {
  width: 20px;
  height: 20px;
  color: #999;
  text-decoration: none;
  font-size: 16px;
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 5px;
}
.bd_weixin_popup_close img {
  width: 9px;
  height: 9px;
  cursor: pointer;
}
#qrcode {
  width: 185px;
  margin: 0 auto;
  padding: 15px 10px;
}
#qrcode img {
  margin: 0 auto;
}

.share_footer {
  display: block;
  font-size: 12px;
  text-align: center;
  line-height: 22px;
  color: #666;
}
@media screen and (max-width: 1500px) {
  .ce {
    position: absolute;
    right: 158px;
    top: 40%;
  }
}
</style>


<script>
import * as THREE from "../three.module.js";
import { OrbitControls } from "../OrbitControls.js";
import { FBXLoader } from "../three/FBXLoader";
import { fetch } from "../api/https";
import QRCode from "qrcodejs2";
export default {
  data() {
    return {
      id: "",
      detail: [],
      loadProgress: 0,
      id: "",
      name: "象牙雕观世音",
      chao: "明",
      list: [],
      images: "",
      share_flag: false,
      num: 0,
    };
  },
  async mounted() {
    let self = this;
    this.id = this.$route.query.goods_id;
    let res = await fetch(`/collect/detail/${this.id}`);
    this.detail = res.data;
    // let objUrl =
    //   "http://bowuguanjiekou.elongtian.com/data/uploads/attachment/20210412/860fc71a890508e48df4863d86ba37c7.fbx";
    let objUrl = `https://www.symuseum.cn/api${this.detail.path}`;
    let containerEl = document.getElementById("containera");
    let width = containerEl.getBoundingClientRect().width;
    let height = containerEl.getBoundingClientRect().height;
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000);
    camera.position.z = 5;
    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    renderer.setClearAlpha(0);
    renderer.setSize(width, height);
    function render() {
      renderer.render(scene, camera);
    }

    containerEl.appendChild(renderer.domElement);

    const ambientLight = new THREE.AmbientLight(0xffffff, this.detail.x);
    scene.add(ambientLight);

    let object;
    function loadModel() {
      object.position.y = self.detail.y;
      object.rotation.y = 0.5;
      object.rotation.x = self.detail.h;
      object.scale.set(self.detail.z, self.detail.z, self.detail.z);
      scene.add(object);
      render();
    }
    const manager = new THREE.LoadingManager(loadModel);

    const loader = new FBXLoader(manager);
    loader.load(
      objUrl,
      function (obj) {
        object = obj;
      },
      onProgress
    );
    function onProgress(xhr) {
      // 后台打印查看模型文件加载进度
      let loadProgress = Math.floor((xhr.loaded / xhr.total) * 100);
      self.loadProgress = loadProgress;
    }

    // window.addEventListener("keypress", (e) => {
    //   let key = e.key.toLowerCase();
    //   if (key === "w") {
    //     object.rotation.x -= 0.05;
    //   }
    //   if (key === "s") {
    //     object.rotation.x += 0.05;
    //   }
    //   if (key === "a") {
    //     object.rotation.y -= 0.05;
    //   }
    //   if (key === "d") {
    //     object.rotation.y += 0.05;
    //   }
    //   render();
    // });

    window.addEventListener("wheel", () => {});

    function onWindowResize() {
      let width = containerEl.getBoundingClientRect().width;
      let height = containerEl.getBoundingClientRect().height;
      let aspect = width / height;
      renderer.setSize(width, height);
      camera.aspect = aspect;
      camera.updateProjectionMatrix();
      render();
    }
    window.addEventListener("resize", () => {
      onWindowResize();
    });
    let controls = new OrbitControls(camera, renderer.domElement);

    controls.listenToKeyEvents(window);
    controls.addEventListener("change", render);

    controls.screenSpacePanning = true;

    // controls.minDistance = 3;
    // controls.maxDistance = 6;
    controls.minDistance = 3;
    controls.maxDistance = 12;
    // this.qrcode()
  
    
  },
  methods: {
     
    async qrcode() {
      await this.$nextTick(() => {
        var qrcode = new QRCode("qrcode", {
          text: window.location.href,
          width: 185,
          height: 185,
          colorDark: "#000000",
          colorLight: "#ffffff",
          correctLevel: QRCode.CorrectLevel.H,
        });
      });
    },
    guan() {
      this.share_flag = false;
      this.num = 0;
    },
    haveCode() {
      this.num++;
      if (this.num == 2) {
        return false;
      }
      this.share_flag = true;
      this.qrcode();
    },
    sinlang() {
      var href = window.location.href;
      var routeUrl =
        "https://service.weibo.com/share/share.php?title=" +
        this.detail.name +
        "&url=" +
        href +
        "&pic=" +
        this.detail.img;
      window.open(routeUrl, "_blank");
    },
  },
};
</script>